import { Box, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { Button } from "@components/atoms/Button";
import { useLocalisationContext } from "@providers/LocalisationProvider/hooks/useLocalisationContext";
import { getHumanReadableLangFromLang } from "@domain/localisation/common/getHumanReadableLangFromLang";
import { onError } from "@integration/bugsnag/csr";
import { LANGUAGE_BTN_TEST_ID } from "./constants/testIds";
import type {
  Languages,
  HumanReadableLanguage,
} from "@domain/localisation/localisation-config/types";
import type { LanguageSwitchProps } from "./LanguageSwitch.types";

export const LanguageSwitch = ({
  sx,
  includedLanguages,
  disabled,
}: LanguageSwitchProps) => {
  const { setCurrentLang, humanReadableLang } = useLocalisationContext();
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const [selectedLang, setSelectedLang] = useState(humanReadableLang);

  const handleSetOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleLangSelect = (
    lang: Languages,
    langName: HumanReadableLanguage,
  ) => {
    setSelectedLang(langName);
    setCurrentLang(lang);
    setIsOpen(false);
  };

  const langMenuItems = includedLanguages.map((lang) => ({
    label: getHumanReadableLangFromLang({ lang, onError }),
    value: lang,
  }));

  return (
    <Box sx={sx}>
      <Button
        buttonLabel={selectedLang}
        data-testid={LANGUAGE_BTN_TEST_ID}
        id="lang-select-button"
        aria-controls={isOpen ? "language-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? "true" : undefined}
        disabled={disabled}
        onClick={handleSetOpen}
      />

      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        MenuListProps={{
          "aria-labelledby": "lang-select-button",
        }}
        sx={{ zIndex: 20000 }} // has to go over filter bar
      >
        {langMenuItems.map((item, idx) => (
          <MenuItem
            key={idx}
            value={item.value}
            onClick={() =>
              handleLangSelect(item.value, item.label as HumanReadableLanguage)
            }
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
