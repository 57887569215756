import { useMemo } from "react";
import { useExperiments } from "@providers/ExperimentsProvider/hooks/useExperiments";
import { LEVEL_UP_FEATURE_ENABLED_EXP_ID } from "@integration/experiments/constants/experimentIds";
import { isFeatureEnabled } from "@domain/features/csr";
import { type BrandId } from "@domain/branding/common";
import { fetchProductConfigCSR } from "@domain/product-config/csr";
import {
  type NavBarBadgeId,
  type NavBarButtonHighlights,
} from "../NavBar.types";

type Props = {
  brandId: BrandId;
};

export const useNavBarHighlights = ({
  brandId,
}: Props): { highlights: NavBarButtonHighlights } => {
  const { experiments } = useExperiments();

  const highlightedButtons = useMemo(() => {
    const highlightedButtons: NavBarBadgeId[] = [];
    const { levelUp } = fetchProductConfigCSR(brandId);
    if (levelUp && levelUp.enabled) {
      const showBadge = isFeatureEnabled({
        feature: LEVEL_UP_FEATURE_ENABLED_EXP_ID,
        experiments: experiments || null,
      });
      if (showBadge) {
        highlightedButtons.push("profile");
      }
    }
    return highlightedButtons;
  }, [brandId, experiments]);

  return { highlights: { buttons: highlightedButtons } };
};
