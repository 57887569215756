import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import { useState, Fragment } from "react";
import { Box, Chip, Stack, useTheme } from "@mui/material";
import { Text } from "@components/atoms/Text";
import { useLocalisationContext } from "@providers/LocalisationProvider/hooks/useLocalisationContext";
import { NAV_MENU_BUTTON_TEST_ID, LOG_OUT_MENU_ITEM_TEST_ID } from "./testIds";
import type { MouseEvent } from "react";
import type { HamburgerMenuProps } from "./HamburgerMenu.types";

export const HamburgerMenu = <T,>({
  options = [],
  variant = "primary",
  onClick,
  sx,
  disabled,
}: HamburgerMenuProps<T>) => {
  const { t } = useLocalisationContext();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (id: T) => {
    handleClose();
    onClick(id);
  };

  const MenuButtonIcon = open ? CloseIcon : MenuIcon;

  return (
    <div>
      <IconButton
        id="positioned-button"
        data-testid={NAV_MENU_BUTTON_TEST_ID}
        aria-controls={open ? "positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        disabled={disabled}
        sx={{
          ...sx,
          opacity: disabled ? 0.25 : 1,
          cursor: disabled ? "default" : "pointer",
        }}
      >
        <MenuButtonIcon
          sx={{
            color: variant === "primary" ? "white" : theme.palette.primary.main,
          }}
        />
      </IconButton>

      <Menu
        PaperProps={{
          sx: {
            boxShadow: "none",
            width: "100%",
            maxWidth: "100%",
            left: "0!important",
            top: "59px!important",
            anchorOrigin: {
              horizontal: 0,
              vertical: 50,
            },
            backgroundColor:
              variant === "primary" ? theme.palette.primary.main : "white",
            borderRadius: `0 0 ${theme.styles.borderRadius.large} ${theme.styles.borderRadius.large}`,
          },
        }}
        id="positioned-menu"
        aria-labelledby="positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Box p={2}>
          {options.map((option, idx) => (
            <Fragment key={idx}>
              {idx === options.length - 1 && (
                <Divider
                  color={
                    variant === "primary"
                      ? "white"
                      : theme.typography.h3mobile.color
                  }
                />
              )}
              <MenuItem
                onClick={() => handleMenuItemClick(option.id)}
                data-testid={
                  idx === options.length - 1
                    ? LOG_OUT_MENU_ITEM_TEST_ID
                    : undefined
                }
                sx={{ justifyContent: "center" }}
              >
                {option.new ? (
                  <Stack
                    direction={"row"}
                    justifyContent={"flex-end"}
                    sx={{ flex: 1 }}
                  >
                    <Chip
                      label={t("common.new")}
                      color="success"
                      sx={{ mr: 2 }}
                    />
                  </Stack>
                ) : (
                  <Box sx={{ flex: 1 }} />
                )}

                <Text
                  variant="h3mobile"
                  sx={{
                    textAlign: "center",
                    color:
                      variant === "secondary"
                        ? theme.typography.h3mobile.color
                        : "white",
                  }}
                >
                  {option.label}
                </Text>

                <Box sx={{ flex: 1 }} />
              </MenuItem>
            </Fragment>
          ))}
        </Box>
      </Menu>
    </div>
  );
};
