import { type ReactNode, useEffect } from "react";
import {
  Badge,
  Stack,
  type SxProps,
  type Theme,
  Tooltip,
  useTheme,
} from "@mui/material";
import { Text } from "@components/atoms/Text";
import { showEvent } from "@integration/analytics/snowplow";
import type { SX } from "@components/atoms/atoms.types";

export interface NavBarButtonProps extends SX {
  variant: "primary" | "secondary";
  disabled?: boolean;
  showBadge?: boolean;
  onClick: () => void;
  iconStart?: (props: { sx: SxProps<Theme> }) => ReactNode;
  iconEnd?: (props: { sx: SxProps<Theme> }) => ReactNode;
  children: string | null;
  tooltip?: string;
}

export const NavBarButton = ({
  variant,
  sx = [],
  disabled,
  showBadge,
  onClick,
  iconStart,
  iconEnd,
  children,
  tooltip,
}: NavBarButtonProps) => {
  const { themeId, palette } = useTheme();

  useEffect(() => {
    showEvent({ key: "user-profile-button" });
  }, []);

  return (
    <Tooltip title={tooltip}>
      <Stack
        role="button"
        id="demo-positioned-button"
        direction="row"
        alignItems="center"
        onClick={disabled ? undefined : onClick}
        sx={[
          {
            cursor: disabled ? "default" : "pointer",
            opacity: disabled ? 0.25 : 1,
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        {iconStart ? (
          <Badge color="success" variant="dot" invisible={!showBadge}>
            {iconStart({
              sx: {
                color:
                  variant === "primary" && themeId !== "loanonline-ph"
                    ? "white"
                    : palette.primary.main,
              },
            })}
          </Badge>
        ) : null}

        {children ? (
          <Text
            variant="contentBold"
            sx={{
              color:
                variant === "primary" && themeId !== "loanonline-ph"
                  ? palette.common.white
                  : palette.primary.main,
              ml: "4px",
            }}
          >
            {children}
          </Text>
        ) : null}

        {iconEnd ? (
          <Badge color="success" variant="dot" invisible={!showBadge}>
            {iconEnd({
              sx: {
                color:
                  variant === "primary" && themeId !== "loanonline-ph"
                    ? "white"
                    : palette.primary.main,
              },
            })}
          </Badge>
        ) : null}
      </Stack>
    </Tooltip>
  );
};
