import { ErrorBoundary } from "react-error-boundary";
import { shouldHaveLangSwitch } from "@components/pages/EmailRegistration/utils/shouldHaveLangSwitch";
import { onError } from "@integration/bugsnag/csr";
import { branding } from "@domain/branding/common";
import { fetchProfileConfig } from "@domain/product-config/csr";
import { FINMERKADO_PH_BRAND_ID } from "@domain/branding/constants";
import { NavBarFallback } from "./NavBar.fallback";
import { NavBarComponent } from "./NavBar.component";
import { useNavBarHighlights } from "./hooks/useNavBarHighlights";
import type { NavBarProps } from "./NavBar.types";

const AdaptedNavBar = <T,>({
  hasLangSwitch,
  profileButtonContext,
  ...props
}: NavBarProps<T>) => {
  const { enabled: isProfileEnabled } = fetchProfileConfig(branding.brandId);

  const { highlights } = useNavBarHighlights({ brandId: branding.brandId });

  return (
    <NavBarComponent
      {...props}
      hasLangSwitch={
        hasLangSwitch === undefined
          ? shouldHaveLangSwitch(branding.brandId)
          : hasLangSwitch
      }
      onLogoClick={
        branding.brandId === FINMERKADO_PH_BRAND_ID
          ? () => (window.location.href = branding.homepage)
          : undefined
      }
      profileButtonContext={isProfileEnabled ? profileButtonContext : undefined}
      highlights={highlights}
    />
  );
};

export const NavBar = <T,>(props: NavBarProps<T>) => {
  return (
    <ErrorBoundary
      FallbackComponent={() => <NavBarFallback />}
      onError={onError}
    >
      <AdaptedNavBar {...props} />
    </ErrorBoundary>
  );
};
