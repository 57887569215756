import { Box, Stack } from "@mui/material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { branding } from "@domain/branding/common";
import { HamburgerMenu } from "@components/molecules/HamburgerMenu";
import { useThemeContext } from "@providers/ThemeProvider/hooks";
import { useLocalisationContext } from "@providers/LocalisationProvider/hooks/useLocalisationContext";
import { LanguageSwitch } from "@components/molecules/LanguageSwitch";
import { DynamicBrandLogo } from "../../molecules/DynamicBrandLogo/DynamicBrandLogo";
import { NavBarButton } from "./components/NavBarButton";
import type { NavBarProps } from "./NavBar.types";

export const NavBarComponent = <T,>({
  sx = [],
  variant,
  menuProps,
  profileButtonContext,
  showInPartnershipWith,
  hasLangSwitch,
  disabled,
  onLogoClick,
  highlights,
}: NavBarProps<T>) => {
  const { theme, themeId } = useThemeContext();
  const { t, allowedLanguages } = useLocalisationContext();

  return (
    <Stack
      height="40px"
      direction="row"
      justifyContent="space-between"
      sx={[
        {
          backgroundColor:
            variant === "primary"
              ? theme.styles.colors.navBarBackground
              : "white",
          paddingTop: "10px",
          paddingBottom: "10px",
          width: "100%",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <DynamicBrandLogo
        variant={variant}
        brandId={themeId}
        onLogoClick={onLogoClick}
      />

      <Stack direction="row" alignItems="center">
        {hasLangSwitch && (
          <LanguageSwitch
            disabled={disabled}
            sx={{ ml: "16px" }}
            includedLanguages={allowedLanguages}
          />
        )}

        {profileButtonContext && (
          <NavBarButton
            disabled={disabled}
            variant={variant}
            sx={{ ml: "16px" }}
            showBadge={highlights?.buttons.includes("profile")}
            iconStart={(props) => <AccountCircleOutlinedIcon {...props} />}
            onClick={profileButtonContext.onClick}
          >
            {profileButtonContext.isLoggedIn ? t("user_profile.log_in") : null}
          </NavBarButton>
        )}

        {menuProps && (
          <HamburgerMenu
            disabled={disabled}
            onClick={menuProps?.onClick}
            options={menuProps.options}
            variant={
              theme.styles.colors.navBarBackground === "white"
                ? "secondary"
                : variant
            }
            sx={{ marginLeft: "16px" }}
          />
        )}

        {showInPartnershipWith && (
          <Stack flexDirection="row" alignItems="center">
            <Box
              sx={{
                textTransform: "uppercase",
                fontSize: "10px",
                fontWeight: 600,
                ml: "8px",
                mr: "8px",
              }}
            >
              {t("leadPageLanding.in_partnership_with")}
            </Box>

            <DynamicBrandLogo
              variant="secondary"
              size="small"
              brandId={branding.brandId}
            />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
