import { Stack } from "@mui/material";
import { Text } from "@components/atoms/Text";
import { useLocalisationContext } from "@providers/LocalisationProvider/hooks/useLocalisationContext";
import type { NavBarFallbackProps } from "./NavBar.types";

export const NavBarFallback = ({ sx = [] }: NavBarFallbackProps) => {
  const { t } = useLocalisationContext();

  return (
    <Stack
      justifyContent="center"
      sx={[
        (theme) => ({
          minWidth: theme.breakpoints.values.xs - 1,
          minHeight: "56px",
          backgroundColor: "white",
          border: `2px solid ${theme.palette.primary[900]}`,
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Text
        variant="contentDefault"
        sx={{
          height: "100%",
          paddingTop: "10px",
          paddingBottom: "10px",
        }}
      >
        {t("cards.general_error")}
      </Text>
    </Stack>
  );
};
